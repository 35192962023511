export const riskCategoriesCITES = {
    appendixI: {
        title: 'Apéndice I',
        icon: 'icon-appendix-1'
    },
    appendixII: {
        title: 'Apéndice II',
        icon: 'icon-appendix-2'
    },
    appendixIII: {
        title: 'Apéndice III',
        icon: 'icon-appendix-3'
    }
};
