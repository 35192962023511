import React from 'react';
import {Route, Switch, Redirect} from 'react-router';

import Home from './components/home';
import AnimalSpecies from './components/animal-species';

export default (
    <Switch>
        <Route exact path='/' component={Home} />
        <Redirect from='/animales/:slug?' to='/especies/:slug?' />
        <Route exact path='/especies/:slug?' component={AnimalSpecies} />
    </Switch>
);
