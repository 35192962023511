import '../styles/contact-form.scss';

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import classNames from 'classnames';

import {submitContactForm} from '../actions/contact';

import Dialog from './dialog';

class ContactForm extends Component {
    static propTypes = {
        submitError: PropTypes.object,
        submitted: PropTypes.bool.isRequired,
        submitting: PropTypes.bool.isRequired,
        submitContactForm: PropTypes.func.isRequired
    };

    constructor (props) {
        super(props);

        this.state = {
            name: '',
            email: '',
            subject: '',
            message: '',
            dialog: false,
            disabled: false
        };
    }

    static getDerivedStateFromProps (props, state) {
        if (props.submitting) {
            return {
                dialog: null,
                disabled: true,
                submitting: true,
                errorMessage: false
            };
        }

        if (state.submitting && props.submitted) {
            return {
                name: '',
                email: '',
                subject: '',
                message: '',
                dialog: true,
                disabled: false,
                submitting: false,
                errorMessage: false
            };
        }

        if (props.submitError) {
            return {
                disabled: false,
                submitting: false,
                errorMessage: 'Ha ocurrido un error. Intente de nuevo.'
            };
        }

        return null;
    }

    render () {
        return (
            <div className='contact-form'>
                <form onSubmit={this.handleSubmit}>
                    <div className='input-wrapper'>
                        <label>Nombre *</label>
                        <input
                            id='name'
                            type='text'
                            placeholder='Nombre'
                            value={this.state.name}
                            onChange={this.handleChange}
                            disabled={this.state.disabled}
                            className={classNames({invalid: this.state.nameError})} />
                    </div>
                    <div className='input-wrapper'>
                        <label>Correo Electrónico *</label>
                        <input
                            id='email'
                            type='text'
                            value={this.state.email}
                            onChange={this.handleChange}
                            disabled={this.state.disabled}
                            placeholder='Correo electrónico'
                            className={classNames({invalid: this.state.emailError})} />
                    </div>
                    <div className='input-wrapper'>
                        <label>Asunto *</label>
                        <input
                            id='subject'
                            type='text'
                            placeholder='Asunto'
                            value={this.state.subject}
                            onChange={this.handleChange}
                            disabled={this.state.disabled}
                            className={classNames({invalid: this.state.subjectError})} />
                    </div>
                    <div className='input-wrapper large'>
                        <label>Mensaje *</label>
                        <textarea
                            id='message'
                            value={this.state.message}
                            onChange={this.handleChange}
                            disabled={this.state.disabled}
                            placeholder='Escribe tu mensaje...'
                            className={classNames({invalid: this.state.messageError})} />
                    </div>
                    <span className='required-text'>* Campos requeridos</span>
                    <button
                        type='submit'
                        disabled={this.state.disabled}>
                        {this.state.disabled ? 'Enviando' : 'Enviar'}
                    </button>
                </form>
                {this.renderDialog()}
            </div>
        );
    }

    renderDialog () {
        if (!this.state.dialog) {
            return;
        }

        return (
            <Dialog
                content={
                    <>
                        <h6>Tu mensaje ha sido enviado</h6>
                        <span>Muchas gracias</span>
                    </>
                }
                onHide={this.hideDialog} />
        );
    }

    hideDialog = () => {
        this.setState({dialog: false});
    }

    handleChange = (event) => {
        const state = {};
        state[event.target.id] = event.target.value;
        state[event.target.id + 'Error'] = false;

        this.setState(state);
    }

    handleSubmit = (event) => {
        event.preventDefault();

        const state = {};
        let invalidFields = false;

        if (!this.state.name.trim()) {
            invalidFields = true;
            state.nameError = true;
        }

        if (!this.state.email.trim() || !this.isValidEmail(this.state.email)) {
            invalidFields = true;
            state.emailError = true;
        }

        if (!this.state.subject) {
            invalidFields = true;
            state.subjectError = true;
        }

        if (!this.state.message.trim()) {
            invalidFields = true;
            state.messageError = true;
        }

        if (invalidFields) {
            return this.setState(state);
        }

        this.props.submitContactForm(this.state.name, this.state.email, this.state.subject, this.state.message);
    }

    /* eslint-enable no-invalid-this */

    isValidEmail (email) {
        return (/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i).test(email);
    }
}

const mapStateToProps = (state) => {
    return {
        submitting: state.contact.submitting,
        submitted: state.contact.submitted,
        submitError: state.contact.submitError
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        submitContactForm: submitContactForm
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
