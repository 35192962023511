import '../styles/dialog.scss';

import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class Dialog extends Component {
    static propTypes = {
        content: PropTypes.any.isRequired,
        onHide: PropTypes.func.isRequired
    };

    render () {
        return (
            <div className='dialog-backdrop'>
                <div className='dialog'>
                    <span className='icon-close' onClick={this.props.onHide} />
                    <div className='dialog-content'>
                        {this.props.content}
                    </div>
                </div>
            </div>
        );
    }
}
