import '../styles/index.scss';

import React, {Component} from 'react';

import routes from '../routes';

import Header from './header';
import Footer from './footer';

export default class Main extends Component {
    render () {
        return (
            <main>
                <Header />
                {routes}
                <Footer />
            </main>
        );
    }
}
