import '../styles/slideshow.scss';

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Swipeable from 'react-swipeable';

class Slideshow extends Component {
    static propTypes = {
        children: PropTypes.array.isRequired
    };

    constructor (props) {
        super(props);

        this.state = {
            selectedIndex: 0
        };
    }

    render () {
        return (
            <div className='slideshow'>
                <Swipeable
                    onSwipedLeft={this.selectNextItem}
                    onSwipedRight={this.selectPreviousItem}>
                    <div className='background' />
                    {this.processChildren()}
                    {this.renderLeftArrow()}
                    {this.renderRightArrow()}
                </Swipeable>
            </div>
        );
    }

    processChildren () {
        const items = [];
        const self = this;

        React.Children.forEach(this.props.children, function (child, index) {
            items.push(
                React.cloneElement(child, {
                    key: 'slideshow-item-' + index,
                    className: classNames('item', {selected: self.state.selectedIndex === index}, child.props.className)
                })
            );
        });

        return items;
    }

    renderLeftArrow () {
        if (this.state.selectedIndex === 0) {
            return;
        }

        return (
            <span
                className='icon-left brand-primary-color brand-primary-dark-color-hover'
                onClick={this.selectPreviousItem} />
        );
    }

    renderRightArrow () {
        if (this.state.selectedIndex === this.props.children.length - 1) {
            return;
        }

        return (
            <span
                className='icon-right brand-primary-color brand-primary-dark-color-hover'
                onClick={this.selectNextItem} />
        );
    }

    /* eslint-disable no-invalid-this */

    selectItem = (index) => {
        this.setState({selectedIndex: index});
    }

    selectPreviousItem = (event) => {
        if (this.state.selectedIndex === 0) {
            return;
        }

        this.setState({selectedIndex: this.state.selectedIndex - 1});
    }

    selectNextItem = (event) => {
        if (this.state.selectedIndex === this.props.children.length - 1) {
            return;
        }

        this.setState({selectedIndex: this.state.selectedIndex + 1});
    }

    /* eslint-enable no-invalid-this */
}

export default Slideshow;
