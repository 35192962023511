import '../styles/animal-species.scss';

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {NavLink} from 'react-router-dom';

import {animalSpecies} from '../data/animal-species';

import AnimalDataSheet from './animal-data-sheet';

class AnimalSpecies extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired
    };

    componentDidMount () {
        window.scrollTo(0, 0);
    }

    render () {
        if (this.props.match.params.slug) {
            return (<AnimalDataSheet slug={this.props.match.params.slug} />);
        }

        return (
            <div className='animal-species'>
                <div className='centered'>
                    <h1>Especies</h1>
                    {this.renderAnimalSpecies()}
                </div>
            </div>
        );
    }

    renderAnimalSpecies () {
        const self = this;
        const elements = [];

        for (const slug in animalSpecies) {
            if (!animalSpecies.hasOwnProperty(slug)) {
                continue;
            }

            const animal = animalSpecies[slug];
            elements.push(
                <div
                    key={'animal-' + animal.slug}
                    className='animal-outter-wrapper'>
                    <NavLink to={'/animales/' + animal.slug} className='animal-inner-wrapper'>
                        <div className='image-wrapper'>
                            {self.renderImage(animal)}
                        </div>
                        <div className='basic-info'>
                            <h6>{animal.shortName || animal.commonName}</h6>
                            <span className='english-name'>{'(' + (animal.englishName || '-') + ')'}</span>
                        </div>
                    </NavLink>
                </div>
            );
        }

        return elements;
    }

    renderImage (animal) {
        if (!animal.images || !animal.images.length) {
            return (
                <div style={{backgroundImage: `url(${require('../assets/species/placeholder.png').default})`}} />
            );
        }

        return (
            <div style={{backgroundImage: `url(${require('../assets/species/' + animal.slug + '/' + animal.images[0] + '.jpg').default})`}} />
        );
    }
}

export default withRouter(AnimalSpecies);
