import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';

import ContactForm from './contact-form';

export default class Home extends Component {
    render () {
        return (
            <div className='home'>
                <div className='hero-wrapper'>
                    <div className='hero-img' />
                    <div className='centered'>
                        <p>¡Ven y conoce más de <span>100 especies</span> de distintas regiones del planeta!</p>
                    </div>
                </div>
                <section className='about-section' id='acerca'>
                    <div className='about-info-wrapper centered'>
                        <div className='text-wrapper'>
                            <h1>Zoológico</h1>
                            <h2>La Pastora</h2>
                            <p>El Zoológico La Pastora fue creado con el objeto de proporcionar cultura a sus visitantes, así como la de mantener y perseverar especies de flora y fauna silvestre.<br />El parque fue inaugurado oficialmente en julio de 1985, y desde entonces a la fecha ha ofrecido a toda la comunidad un lugar de esparcimiento y recreo.<br /><br />Recorre el parque y conoce más de 100 especies animales de distintas regiones del planeta. Aprende sobre sus características, cuidados y estado de conservación actual.</p>
                        </div>
                        <div className='about-image-wrapper'>
                            <img
                                alt='elephant'
                                src={require('../assets/elephant.png').default} />
                        </div>
                    </div>
                    <div className='about-info-wrapper centered right-text'>
                        <div className='about-image-wrapper desktop'>
                            <img
                                alt='buffalo'
                                src={require('../assets/buffalo.png').default} />
                        </div>
                        <div className='text-wrapper'>
                            <h2>Paseo La Pastora</h2>
                            <p>Es un recorrido libre que puedes realizar con tu familia antes de la entrada al zoológico, sus jardines cuentan con árboles, flores de colores vistosos y por las noches una hermosa fuente mostrará una combinación de luces que forman excelentes complementos visuales para las fotografías de los visitantes. Además, podrás aprovechar diferentes áreas de restaurantes y servicios.</p>
                        </div>
                        <div className='about-image-wrapper mobile'>
                            <img
                                alt='buffalo'
                                src={require('../assets/buffalo.png').default} />
                        </div>
                    </div>
                    <div className='about-info-wrapper centered'>
                        <div className='text-wrapper'>
                            <h2>Breve historia</h2>
                            <p>El nombre del parque tiene su origen en la Estancia o Hacienda de la Pastora que antiguamente se encontraba en esta ubicación, fundada en 1598 por Juan López, y que fue una de las primeras estancias del Nuevo Reino de León.</p>
                            <p>El parque que denominado La Pastora fue adquirido por el gobierno del Estado de Nuevo León a mediados de la década de 1980 para dotar a la ciudad de Monterrey de un parque urbano. Es un área de poco más un millón de metros cuadrados, dentro de los cuales con el paso del tiempo se establecio el Parque Zoológico La Pastora.</p>
                        </div>
                        <div className='about-image-wrapper'>
                            <img
                                alt='zebra'
                                src={require('../assets/zebra.png').default} />
                        </div>
                    </div>
                    <div className='centered'>
                        <NavLink
                            to='/animales'
                            className='species-link'>
                            <span>¡Conoce más sobre las especies de La Pastora!</span>
                        </NavLink>
                    </div>
                    <div className='about-footer'>
                        <div className='footer-img' style={{backgroundImage: `url(${require('../assets/about-footer.png').default})`}} />
                    </div>
                </section>
                <section className='opening-hours-section' id='horarios'>
                    <span className='icon-clock' />
                    <div className='centered'>
                        <h1>HORARIO</h1>
                        <div className='opening-hours-wrapper'>
                            <span className='days'>Martes a Jueves</span>
                            <span className='hours'>Taquilla - 9:00 a 17:00 hrs.</span>
                            <span className='hours'>Parque - 9:00 a 18:00 hrs.</span>
                        </div>
                        <div className='opening-hours-wrapper'>
                            <span className='days'>Viernes a Domingo</span>
                            <span className='hours'>Taquilla - 9:00 a 18:30 hrs.</span>
                            <span className='hours'>Parque - 9:00 a 20:00 hrs.</span>
                        </div>
                        <div className='opening-hours-wrapper'>
                            <span className='days'>Lunes</span>
                            <span className='hours'>Cerrado</span>
                        </div>
                    </div>
                </section>
                <section className='prices-section'>
                    <div className='centered'>
                        <h1>COSTOS</h1>
                        <div className='prices-wrapper'>
                            <h3>Adultos $60.00</h3>
                            <span className='price-caption'>(A partir de 13 años)</span>
                            <h3>Niños $30.00</h3>
                            <span className='price-caption'>(Menores de 3 a 12 años)</span>
                            <h3>INAPAM $30.00</h3>
                            <span className='price-caption'>(Adultos Mayores con credencial)</span>
                            <span className='free-entrance-caption'>Menores de 2 años y personas con discapacidad GRATIS</span>
                        </div>
                        <div className='image-wrapper'>
                            <img
                                alt='alligator'
                                src={require('../assets/alligator.png').default} />
                        </div>
                    </div>
                </section>
                <section className='regulation-section'>
                    <span className='icon-rules' />
                    <div className='centered'>
                        <h1>REGLAMENTO</h1>
                        <h2>Interno</h2>
                        <span className='prohibited-caption'>Queda prohibido:</span>
                        <ul>
                            <li>
                                <span className='icon-prohibited' />
                                <span className='rule-text'>Ingresar alimentos</span>
                            </li>
                            <li>
                                <span className='icon-prohibited' />
                                <span className='rule-text'>Ingresar armas</span>
                            </li>
                            <li>
                                <span className='icon-prohibited' />
                                <span className='rule-text'>Introducir bebidas alcohólicas</span>
                            </li>
                            <li>
                                <span className='icon-prohibited' />
                                <span className='rule-text'>Utilizar bicicletas, patines y/o patinetas</span>
                            </li>
                            <li>
                                <span className='icon-prohibited' />
                                <span className='rule-text'>Traer mascotas</span>
                            </li>
                            <li>
                                <span className='icon-prohibited' />
                                <span className='rule-text'>Fumar dentro del parque</span>
                            </li>
                        </ul>
                    </div>
                </section>
                <section className='location-section'>
                    <div className='centered'>
                        <h1>UBICACIÓN</h1>
                        <div className='location-wrapper'>
                            <div className='image-wrapper mobile'>
                                <img
                                    alt='location-map'
                                    src={require('../assets/map.png').default} />
                            </div>
                            <div className='location-address-wrapper'>
                                <span className='icon-location' />
                                <div className='address'>
                                    <span>Av. Pablo Livas s/n</span>
                                    <span>Col. Jardines de La Pastora</span>
                                    <span>Guadalupe, Nuevo León C.P. 67140</span>
                                    <a
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        href='https://goo.gl/maps/YXQoXBsuf4TmsjBT6'>
                                        Ver en Google Maps
                                    </a>
                                </div>
                            </div>
                            <div className='image-wrapper desktop'>
                                <img
                                    alt='location-map'
                                    src={require('../assets/map.png').default} />
                            </div>
                        </div>
                    </div>
                </section>
                <section className='contact-section' id='contacto'>
                    <div className='centered'>
                        <h1>CONTACTO</h1>
                        <div className='image-wrapper'>
                            <img
                                alt='tiger'
                                src={require('../assets/tiger.png').default} />
                        </div>
                        <div className='contact-info-outer-wrapper'>
                            <div className='contact-info-wrapper'>
                                <h6>Teléfonos</h6>
                                <span className='icon icon-telephone' />
                                <a href='tel:+52-81-8126-8500'>
                                    (81) 8126.8500
                                </a>
                            </div>
                            <div className='contact-info-wrapper'>
                                <h6>Email</h6>
                                <span className='icon icon-email' />
                                <a href='mailto:contacto@parquelapastora.org'>
                                    contacto@parquelapastora.org
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='contact-form-section'>
                    <div className='centered'>
                        <h3>Evíanos un mensaje</h3>
                        <ContactForm />
                        <span className='icon-postcard' />
                    </div>
                </section>
            </div>
        );
    }
}
