import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware} from 'redux';
import reducers from './reducers/index';
import thunk from 'redux-thunk';
import PropTypes from 'prop-types';
import {withRouter, matchPath} from 'react-router';
import {BrowserRouter} from 'react-router-dom';
import routes from './routes';

import Main from './components/main';

let App = (props) => {
    function getMatchedRoute (pathname) {
        for (let i = 0; i < routes.props.children.length; i++) {
            const route = routes.props.children[i].props;
            const match = matchPath(pathname, route);
            if (route.path && match) {
                return {route, match};
            }
        }

        return {};
    }

    return (<Main route={getMatchedRoute(props.location.pathname).route} {...props} />);
};

App.propTypes = {
    location: PropTypes.object
};

App = withRouter(App);

ReactDOM.render(
    <Provider store={createStore(reducers, {}, applyMiddleware(thunk))}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>, document.getElementById('root')
);
