export const riskCategoriesNOM = {
    probablyExtinct: {
        title: 'Probablemente extinta',
        icon: 'icon-Extinct'
    },
    dangerOfExtinction: {
        title: 'Peligro de extinción',
        icon: 'icon-in-danger'
    },
    threatened: {
        title: 'Amenazada',
        icon: 'icon-threatened'
    },
    protected: {
        title: 'Protección especial',
        icon: 'icon-special-protection'
    }
};
