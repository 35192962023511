import '../styles/animal-data-sheet.scss';

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {animalSpecies} from '../data/animal-species';
import {riskCategoriesNOM} from '../data/risk-categories-nom';
import {riskCategoriesCITES} from '../data/risk-categories-cites';
import {riskCategoriesIUCN} from '../data/risk-categories-iucn';

import Slideshow from './slideshow';

export default class AnimalDataSheet extends Component {
    static propTypes = {
        slug: PropTypes.string.isRequired
    };

    componentDidMount () {
        window.scrollTo(0, 0);
    }

    render () {
        const animal = animalSpecies[this.props.slug];

        return (
            <div className='animal-data-sheet'>
                <div className='centered'>
                    <div className='name-image-wrapper'>
                        <div className='image-wrapper'>
                            {this.renderImage(animal)}
                        </div>
                        <div className='name-details'>
                            <h5>{animal.commonName}</h5>
                            <span className='english-name'>{'(' + (animal.englishName || '-') + ')'}</span>
                            <span className='scientific-name'>{animal.scientificName}</span>
                        </div>
                    </div>
                    <div className='detailed-info-wrapper'>
                        <div className='left-column'>
                            <IconInfoWrapper
                                icon='icon-info'
                                title='Órden:'
                                value={animal.order} />
                            <IconInfoWrapper
                                title='Familia:'
                                value={animal.family} />
                            {this.renderSubfamily(animal)}
                            <IconInfoWrapper
                                icon='icon-distribution'
                                value={animal.location} />
                            <IconInfoWrapper
                                icon='icon-habitat'
                                value={animal.habitat} />
                            <IconInfoWrapper
                                icon='icon-feeding'
                                value={animal.feeding} />
                        </div>
                        <div className='right-column'>
                            <IconInfoWrapper
                                icon='icon-id'
                                className='identification'
                                value={animal.identification} />
                            {this.renderGestation(animal)}
                        </div>
                    </div>
                    <div className='risk-info-wrapper'>
                        <span className='actual-situation'>Situación Actual:</span>
                        {this.renderRiskInfo(animal)}
                    </div>
                </div>
                {this.renderImageGallery(animal)}
                <div className='iconography'>
                    <div className='centered'>
                        <h6>Iconografía</h6>
                        <IconInfoWrapper
                            icon='icon-distribution'
                            value='Distribución' />
                        <IconInfoWrapper
                            icon='icon-habitat'
                            value='Hábitat' />
                        <IconInfoWrapper
                            icon='icon-id'
                            value='Identificación' />
                        <IconInfoWrapper
                            icon='icon-feeding'
                            value='Alimentación' />
                        <IconInfoWrapper
                            icon='icon-gestation'
                            value='Período de gestación/ incubación' />
                        <span className='icon-elephant' />
                    </div>
                </div>
            </div>
        );
    }

    renderImage (animal) {
        if (!animal.images || !animal.images.length) {
            return (
                <div style={{backgroundImage: `url(${require('../assets/species/placeholder.png').default})`}} />
            );
        }

        return (
            <div style={{backgroundImage: `url(${require('../assets/species/' + animal.slug + '/' + animal.images[0] + '.jpg').default})`}} />
        );
    }

    renderRiskInfo (animal) {
        const riskElements = [];
        if (!animal.riskNOM && !animal.riskCITES && !animal.riskIUCN) {
            riskElements.push(
                <div className='risk-element'>
                    <div className='risk-options'>
                        <div className='risk-option active'>
                            <span className='icon icon-no-risk' />
                            <span className='title'>No en riesgo</span>
                        </div>
                    </div>
                </div>
            );

            return riskElements;
        }

        if (animal.riskNOM) {
            riskElements.push(
                <div className='risk-element'>
                    <span className='risk-type'>NOM-059-SEMARNAT-2010</span>
                    <div className='risk-options'>
                        {this.renderRiskOptions(animal.riskNOM, riskCategoriesNOM)}
                    </div>
                </div>
            );
        }

        if (animal.riskCITES) {
            riskElements.push(
                <div className='risk-element'>
                    <span className='risk-type'>CITES</span>
                    <div className='risk-options'>
                        {this.renderRiskOptions(animal.riskCITES, riskCategoriesCITES)}
                    </div>
                </div>
            );
        }

        if (animal.riskIUCN) {
            riskElements.push(
                <div className='risk-element'>
                    <span className='risk-type'>IUCN RedList</span>
                    <div className='risk-options'>
                        {this.renderRiskOptions(animal.riskIUCN, riskCategoriesIUCN)}
                    </div>
                </div>
            );
        }

        return riskElements;
    }

    renderRiskOptions (risk, categories) {
        const options = [];
        for (const key in categories) {
            if (!categories.hasOwnProperty(key)) {
                continue;
            }

            options.push(
                <div
                    key={'nom-risk-' + key}
                    className={classNames('risk-option', {active: risk === key})}>
                    <span className={classNames('icon', categories[key].icon)} />
                    <span className='title'>{categories[key].title}</span>
                </div>
            );
        }

        return options;
    }

    renderSubfamily (animal) {
        if (!animal.subfamily) {
            return;
        }

        return (
            <IconInfoWrapper
                title='Subfamilia:'
                value={animal.subfamily} />
        );
    }

    renderGestation (animal) {
        if (!animal.gestation) {
            return;
        }

        return (
            <IconInfoWrapper
                icon='icon-gestation'
                value={animal.gestation} />
        );
    }

    renderImageGallery (animal) {
        if (!animal.images || !animal.images.length || animal.images.length < 2) {
            return;
        }

        return (
            <div className='image-gallery'>
                <div className='centered'>
                    <h6>Galería</h6>
                    {this.renderGalleryImages(animal)}
                </div>
            </div>
        );
    }

    renderGalleryImages (animal) {
        const images = [];

        animal.images.forEach((image, index) => {
            images.push(
                <div key={'gallery-image-' + animal.slug + '-' + index} className='image-wrapper'>
                    <div className='image'>
                        <div style={{backgroundImage: `url(${require('../assets/species/' + animal.slug + '/' + image + '.jpg').default})`}} />
                    </div>
                </div>
            );
        });

        return (<Slideshow>{images}</Slideshow>);
    }
}

class IconInfoWrapper extends Component {
    static propTypes = {
        value: PropTypes.any,
        icon: PropTypes.string,
        title: PropTypes.string,
        className: PropTypes.string
    };

    render () {
        return (
            <div className={classNames('icon-info-wrapper', this.props.className)}>
                <span className={classNames('icon', this.props.icon)} />
                <div className='title-value-wrapper'>
                    {this.props.title ? <span className='title'>{this.props.title}</span> : null}
                    <div className='value'>
                        {this.props.value}
                    </div>
                </div>
            </div>
        );
    }
}
