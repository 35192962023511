export const riskCategoriesIUCN = {
    dataDeficient: {
        title: 'Datos insuficientes',
        icon: 'icon-data-deficient'
    },
    leastConcern: {
        title: 'Preocupación menor',
        icon: 'icon-least-concern'
    },
    nearThreatened: {
        title: 'Casi amenzado',
        icon: 'icon-near-threatened'
    },
    vulnerable: {
        title: 'Vulnerable',
        icon: 'icon-vulnerable'
    },
    endangered: {
        title: 'En peligro',
        icon: 'icon-endangered'
    },
    criticallyEndangered: {
        title: 'En peligro crítico',
        icon: 'icon-critically-endangered'
    },
    extinctInTheWild: {
        title: 'Extinto en estado silvestre',
        icon: 'icon-extinct-in-the-wild'
    },
    extinct: {
        title: 'Extinto',
        icon: 'icon-extinct2'
    }
};
