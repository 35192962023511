import '../styles/header.scss';

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {NavLink, Link} from 'react-router-dom';
import {withRouter} from 'react-router';
import classNames from 'classnames';

class Header extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired
    };

    constructor (props) {
        super(props);

        this.state = {
            whiteHeader: false,
            showMobileNav: false
        };
    }

    componentDidMount () {
        window.addEventListener('scroll', this.handleWindowScroll);
    }

    componentWillUnmount () {
        document.removeEventListener('scroll', this.handleWindowScroll);
    }

    render () {
        const useWhiteHeader = this.state.whiteHeader || this.state.showMobileNav;

        return (
            <header className={classNames({'white-header': useWhiteHeader})}>
                <div className='centered'>
                    <Link to='/'>
                        <img
                            alt='pastora-logo'
                            src={require(`../assets/${useWhiteHeader ? 'logo-color' : 'logo-white'}.png`).default} />
                    </Link>
                    <div className={classNames('menu', 'tinted-color', this.state.showMobileNav ? 'icon-close' : 'icon-menu')} onClick={this.toggleMobileNav} />
                    <ul className={classNames({hidden: !this.state.showMobileNav})}>
                        <li>
                            <a href={window.location.origin + '#acerca'} onClick={this.toggleMobileNav}>Acerca</a>
                        </li>
                        <li>
                            <NavLink
                                to='/animales'
                                activeClassName='active'>
                                Especies
                            </NavLink>
                        </li>
                        <li>
                            <a href={window.location.origin + '#horarios'} onClick={this.toggleMobileNav}>Horarios</a>
                        </li>
                        <li>
                            <a href={window.location.origin + '#contacto'} onClick={this.toggleMobileNav}>Contacto</a>
                        </li>
                    </ul>
                </div>
            </header>
        );
    }

    handleWindowScroll = () => {
        if (window.scrollY > 0 && !this.state.whiteHeader) {
            return this.setState({whiteHeader: true});
        }

        if (window.scrollY <= 0 && this.state.whiteHeader) {
            this.setState({whiteHeader: false});
        }
    }

    toggleMobileNav = () => {
        if (window.innerWidth > 850) {
            return;
        }

        this.setState({showMobileNav: !this.state.showMobileNav});
    }
}

export default withRouter(Header);
