export const animalSpecies = {
    'antilope-acuatico': {
        slug: 'antilope-acuatico',
        scientificName: 'Kobus ellipsiprymnus',
        commonName: 'Antílope Acuático',
        englishName: 'Waterbuck',
        riskNOM: null,
        riskCITES: null,
        riskIUCN: null,
        order: 'Artiodactyla',
        family: 'Bovidae',
        subfamily: 'Reduncinae',
        location: 'África, al sur del Sahara',
        habitat: 'Sabanas, cerca del agua',
        identification: 'Longitud del cuerpo hasta 2m. cola 20-35cm. altura 125cm. Manto castaño grisáceo, presenta un semicírculo blanco sobre fondo gris alrededor de la cola. Los cuernos solo se presentan en los machos, miden 75cm. Son gregarios y territoriales, en grupos de 5-30 individuos.',
        gestation: '7 meses aprox. Una sola cría.',
        feeding: 'Herbívoro',
        images: []
    },
    'antilope-adax': {
        slug: 'antilope-adax',
        scientificName: 'Addax nasomaculatus',
        commonName: 'Antílope Adax',
        englishName: 'Addax',
        riskNOM: null,
        riskCITES: 'appendixI',
        riskIUCN: 'criticallyEndangered',
        order: 'Artiodactyla',
        family: 'Bovidae',
        subfamily: 'Hippotraginae',
        location: 'Sahara',
        habitat: 'Desierto',
        identification: 'Longitud del cuerpo 2m. altura de 100cm. cola 20cm. Color entre blanco puro a gris claro, sobre el hocico se dispone un dibujo blanco de las mejillas a la nariz, en la frente pelo denso y negro. Cuernos largos y realizan dos a tres giros en espiral, pezuñas anchas para caminar sobre la arena. Adaptados eficientemente a condiciones desérticas, resiste sin beber y sobrevive con poca vegetación.',
        gestation: 'De 257 a 264 días. Una sola cría.',
        feeding: 'Herbívoro',
        images: []
    },
    'antilope-eland': {
        slug: 'antilope-eland',
        scientificName: 'Taurotragus oryx',
        commonName: 'Antílope Eland',
        englishName: 'Eland',
        riskNOM: null,
        riskCITES: null,
        riskIUCN: null,
        order: 'Artiodactyla',
        family: 'Bovidae',
        subfamily: 'Bovinae',
        location: 'África',
        habitat: 'Sabanas y llanuras',
        identification: 'Longitud de 200-350cm. Altura de 175cm. Cola 30-60cm. Color de fondo del manto es beige-castaño claro, se va haciendo grisáceo con la edad. Bandas blancas en la espalda poco acentuadas. Papada desde garganta al pecho con pelos negros. Otra línea de pelo negro dibuja todo el contorno del cuerpo. Cuernos 75cm. con dos giros espirales. Las hembras son más pequeñas con la cornamenta más ligera. Vive en grupos de 70-80 individuos, a veces los machos son solitarios.',
        gestation: 'Entre 254 y 277 días. Una sola cría.',
        feeding: 'Herbívoro',
        images: []
    },
    'antilope-nilgo': {
        slug: 'nilgo',
        scientificName: 'Boselaphus tragocamelus',
        commonName: 'Antílope Nilgo',
        englishName: 'Nilgai',
        riskNOM: null,
        riskCITES: null,
        riskIUCN: null,
        order: 'Artiodactyla',
        family: 'Bovidae',
        subfamily: 'Bovinae',
        location: 'India',
        habitat: 'Estepas con hierbas y bosques',
        identification: 'Longitud del cuerpo 180-200cm. Cola de 45-55cm. Con una altura de 120-150cm. En los machos el color es gris con reflejos azulados y pardo en las hembras. Los cuernos de los machos miden 20-25cm, las hembras carecen de ellos. La cabeza es larga y estrecha. Cuello corto y tosco. Vive en grupo formados por 8-10 individuos.',
        gestation: 'Finales de marzo a diciembre. Partos con frecuencia gemelares.',
        feeding: 'Herbívoro',
        images: []
    },
    'antilope-azul': {
        slug: 'antilope-azul',
        scientificName: 'Connochaetes taurinus',
        commonName: 'Antílope Ñu Azul',
        englishName: 'Blue wildebeest',
        riskNOM: null,
        riskCITES: null,
        riskIUCN: null,
        order: 'Artiodactyla',
        family: 'Bovidae',
        subfamily: 'Alcelaphinae',
        location: 'De Kenia a África Meridional',
        habitat: 'Sabana y llanura',
        identification: 'Longitud del cuerpo hasta 2m. Cola de 60-100cm. y altura de 160cm. Los cuartos anteriores son más pesados que los posteriores. Crín y cola negros. Color gris azuloso. Cuernos presentes también en las hembras. Son activos tanto de día como de noche. En época de sequía forman grupos de hasta miles de individuos.',
        gestation: 'De 8 a 9 meses. Una sola cría.',
        feeding: 'Herbívoro',
        images: []
    },
    'antilope-orix-cimitarra': {
        slug: 'antilope-orix-cimitarra',
        scientificName: 'Oryx dammah',
        commonName: 'Antílope Orix Cimitarra',
        englishName: 'Scimitar-Horned Oryx',
        riskNOM: null,
        riskCITES: 'appendixI',
        riskIUCN: 'extinctInTheWild',
        order: 'Artiodactyla',
        family: 'Bovidae',
        subfamily: 'Hippotraginae',
        location: 'Norte de África',
        habitat: 'Praderas áridas',
        identification: 'Longitud del cuerpo hasta 190cm. y una altura de 100-105cm. Cola 45-60cm. Cuernos fuertes curvados hacia atrás, pelaje color blanco o amarillento salvo en el cuello, donde el color es herrumbre y parte alta de las extremidades. Penacho oscuro en el extremo de la cola. Observa una máscara facial.',
        gestation: 'De 222 a 256 días y pare una sola cría',
        feeding: 'Herbívoro',
        images: []
    },
    'antilope-orix-cabo': {
        slug: 'antilope-orix-cabo',
        scientificName: 'Oryx gazella',
        commonName: 'Antílope Orix del Cabo',
        englishName: 'Gemsbok',
        riskNOM: null,
        riskCITES: null,
        riskIUCN: null,
        order: 'Artiodactyla',
        family: 'Bovidae',
        subfamily: 'Hippotraginae',
        location: 'Sur de África',
        habitat: 'Pastizales áridos y semiáridos, llanuras arenosas',
        identification: 'Longitud del cuerpo 2m. Altura de 120cm. Cola 55cm. El más grande del género oryx. Cuello y hombros musculosos. Color gris claro desvaneciéndose a blanco hacia la parte trasera. El pelo de la cola es color negro. Una raya negruzca se extiende desde debajo de la barbilla por el borde inferior del cuello, pasando entre las patas y a lo largo de los costados por la parte inferior y hasta el muslo, así también una línea negra por la parte superior del cuello, por el lomo hasta los cuartos posteriores. Sobre las rodillas delanteras observa un parche negro. Ambos sexos con cuernos largos y rectos de 85cm. en promedio. Observa una máscara facial.',
        gestation: 'De 264 días. Una sola cría.',
        feeding: 'Herbívoro',
        images: []
    },
    'avestruz': {
        slug: 'avestruz',
        scientificName: 'Struthio camelus',
        commonName: 'Avestruz',
        englishName: 'Ostrich',
        riskNOM: null,
        riskCITES: null,
        riskIUCN: null,
        order: 'Struthioniformes',
        family: 'Struthionidae',
        subfamily: null,
        location: 'África Oriental',
        habitat: 'Sabana y Estepas',
        identification: 'Altura 2.75m. y una longitud de 2m. Ave de largo cuello, provisto de plumón, cabeza pequeña y aplanada, ojos y patas grandes sin plumas, alas atrofiadas para el vuelo. El plumaje del tronco es negro en los machos y en las hembras gris-pardo. Presenta dos dedos.',
        gestation: null,
        feeding: 'Omnívoro',
        images: []
    },
    'bisonte-americano': {
        slug: 'bisonte-americano',
        scientificName: 'Bos bison',
        commonName: 'Bisonte Americano',
        englishName: 'American Bison or Buffalo',
        riskNOM: 'dangerOfExtinction',
        riskCITES: 'appendixII',
        riskIUCN: 'nearThreatened',
        order: 'Artiodactyla',
        family: 'Ovidae',
        subfamily: 'Bovinae',
        location: 'EE.UU. y Canadá',
        habitat: 'Praderas',
        identification: 'Los machos alcanzan los 3m. de longitud con una altura de 1.9m, cola de 50cm. El manto es castaño obscuro; en la parte anterior de la espalda, cabeza, cuello y patas anteriores el pelo es muy largo. En la frente forma como un penacho y en el mentón adopta la forma de barba. Los cuernos son cortos, curvos y hacia arriba.',
        gestation: '270-285 días aprox. Nace una única cría',
        feeding: 'Herbívoro',
        images: [
            'bisonte-americano-1',
            'bisonte-americano-2',
            'bisonte-americano-3',
            'bisonte-americano-4',
            'bisonte-americano-5',
            'bisonte-americano-6',
            'bisonte-americano-7'
        ]
    },
    'borrego-berberisco': {
        slug: 'borrego-berberisco',
        scientificName: 'Ammotragus lervia',
        commonName: 'Borrego Berberisco',
        englishName: 'Aoudad',
        riskNOM: null,
        riskCITES: 'appendixII',
        riskIUCN: 'vulnerable',
        order: 'Artiodactyla',
        family: 'Bovidae',
        subfamily: 'Caprinae',
        location: 'África septentrional y Sahara',
        habitat: 'Zonas desérticas de montañas',
        identification: 'Longitud de cuperpo 130-180cm. cola 25cm. y altura 100cm. Color uniforme castaño claro a beige. Manto tupido de longitud mediana excepto en garganta, pecho y patas anteriores donde presenta pelo largo. Cuernos gruesos de hasta 75cm. en las hembras son más chicos. En las rocas permanece inmóvil durante el día.',
        gestation: '150 a 165 días. Generalmente una cría. Hasta dos veces por año.',
        feeding: 'Herbívoro',
        images: []
    },
    'borrego-cimarron': {
        slug: 'borrego-cimarron',
        scientificName: 'Ovis canadensis',
        commonName: 'Borrego Cimarrón',
        englishName: 'Mountain Sheep',
        riskNOM: 'protected',
        riskCITES: 'appendixII',
        riskIUCN: null,
        order: 'Artiodactyla',
        family: 'Bovidae',
        subfamily: 'Caprinos',
        location: 'Occidente de América del Norte',
        habitat: 'Alta Montaña',
        identification: 'Longitud del cuerpo de los machos 180cm. Altura 100cm. y cola 7-10cm, peso 120-130kg. Su pelaje es abigarrado; más oscuro en los costados y blanco-amarillento en la parte inferior. Es muy característica la presencia de una mancha posterior de color blanca. Las hembras son más pequeñas y su pelaje aleonado. Ambos sexos presentan cornamenta, forman una espiral a ambos lados de la cabeza y pueden alcanzar en los machos, una longitud de 50cm.',
        gestation: '180 días. La hembra puede parir 1 o 2 crías. Viven en rebaños y la superioridad de los machos se determina por medio de extenuantes duelos. Estos se producen entre ejemplares de cornamentas de igual tamaño.',
        feeding: 'Herbívoro',
        images: null
    },
    'borrego-muflon': {
        slug: 'borrego-muflon',
        scientificName: 'Ovis musimon',
        commonName: 'Borrego Muflón Europeo',
        englishName: 'Mouflon',
        riskNOM: null,
        riskCITES: null,
        riskIUCN: null,
        order: 'Artiodactyla',
        family: 'Bovidae',
        subfamily: 'Caprinae',
        location: 'Córcega, Francia y Cerdeña, Italia',
        habitat: 'Bosque mediterráneo de montaña',
        identification: 'Capa marrón algo más pálido en las hembras. Al año aparece en los machos una mancha blanca en el dorso. Vientre claro. Ocasionalmente se presentan cuernos en las hembras. Cuernos anchos. Presentan una mancha triangular, blanca, en la cara de ambos sexos.',
        gestation: '5 meses. Una cría.',
        feeding: 'Herbívoro',
        images: [
            'borrego-muflon-1',
            'borrego-muflon-2',
            'borrego-muflon-3',
            'borrego-muflon-4',
            'borrego-muflon-5',
            'borrego-muflon-6',
            'borrego-muflon-7',
            'borrego-muflon-8'
        ]
    },
    'bufalo-asiatico': {
        slug: 'bufalo-asiatico',
        scientificName: 'Bubalus bubalis',
        commonName: 'Búfalo Asiático',
        englishName: 'Water Buffalo',
        riskNOM: null,
        riskCITES: null,
        riskIUCN: null,
        order: 'Artiodactyla',
        family: 'Bovidae',
        subfamily: 'Bovinae',
        location: 'India, Birmania, Nepal, Indochina y Filipinas',
        habitat: 'Zonas pantanosas y húmedas',
        identification: 'Cuerpo de 260-280cm., cola de 60-100cm., altura de 150-180cm. Cubierto de pelo color gris o negro, blanco por debajo de la rodilla o en la garganta. Cuernos grandes y de sección triangular. Viven en grupos de 10-20 individuos. Pasan la mayor parte del tiempo sumergidos en agua o nadando en pantanos, alimentándose de hierba. Existe tanto en estado salvaje (Bubalus arnee-Población Amenazada-RedList IUCN), como doméstico, y ha sido introducido en muchos otros lugares.',
        gestation: '300-340 días. Única cría.',
        feeding: 'Herbívoro',
        images: [
            'bufalo-asiatico-1',
            'bufalo-asiatico-2',
            'bufalo-asiatico-3',
            'bufalo-asiatico-4',
            'bufalo-asiatico-5',
            'bufalo-asiatico-6',
            'bufalo-asiatico-7'
        ]
    },
    'caiman-americano': {
        slug: 'caiman-americano',
        scientificName: 'Alligator mississippiensis',
        commonName: 'Caimán Americano',
        englishName: 'American Alligator',
        riskNOM: null,
        riskCITES: 'appendixII',
        riskIUCN: null,
        order: 'Crocodylia',
        family: 'Alligatoridae',
        subfamily: null,
        location: 'Costa sur y sureste de Estados Unidos, desde el centro de Texas hasta el sur de Virginia',
        habitat: 'Pantanos, humedales, ríos y pequeños cuerpos de agua',
        identification: 'Entre 2.7 y 3.5m. de longitud. Su cuerpo es de color marrón-olivo, las áreas alrededor de las mandíbulas, en el cuello y el vientre son de color blanco cremoso. Los jóvenes presentan franjas amarillo brillante y oscuras en los costados y cola.',
        gestation: null,
        feeding: 'Carnívoro',
        images: [
            'caiman-americano-1',
            'caiman-americano-2',
            'caiman-americano-3'
        ]
    },
    'cebra': {
        slug: 'cebra',
        scientificName: 'Equus guagga',
        commonName: 'Cebra',
        englishName: 'Burchell`s Zebra',
        riskNOM: null,
        riskCITES: null,
        riskIUCN: null,
        order: 'Perissodactyla',
        family: 'Equidae',
        subfamily: null,
        location: 'África oriental y meridional',
        habitat: 'Sabana y bosques con claros',
        identification: 'Altura de 140cm. Longitud de 220cm. Color de manto claro y con rayas negras. La crín y es corta e híspida. Forma grupos muy numerosos. Es veloz y durante la fuga mantiene un grupo compacto. Puede estar en rebaños con otras especies de cebras, ñúes y antílopes.',
        gestation: 'Un año. Una única cría.',
        feeding: 'Herbívoro',
        images: []
    },
    'chimpance': {
        slug: 'chimpance',
        scientificName: 'Pan troglodytes',
        commonName: 'Chimpancé',
        englishName: 'Chimpanzee',
        riskNOM: null,
        riskCITES: 'appendixI',
        riskIUCN: 'endangered',
        order: 'Primates',
        family: 'Hominidae',
        subfamily: null,
        location: 'África occidental y central hasta Tanzania',
        habitat: 'Selva en el borde de la Sabana',
        identification: 'El macho mide 115cm. en posición erguida. La hembra es un poco más pequeña. El color del pelaje y rostro depende de la edad, sexo y el medio ambiente. Es un especie arborícola y terrícola. Viven en grupos variable de hastas 40 individuos.',
        gestation: '236 días y la cría depende de la madre los dos primeros años',
        feeding: 'Omnívoro',
        images: []
    },
    'ciervo-muntjac': {
        slug: 'ciervo-muntjac',
        scientificName: 'Muntiacus reevesi r.',
        commonName: 'Ciervo Muntjac',
        englishName: 'Reeves` Muntjac',
        riskNOM: null,
        riskCITES: null,
        riskIUCN: null,
        order: 'Artiodactyla',
        family: 'Cervidae',
        subfamily: 'Muntiacinae',
        location: 'China y Taiwan',
        habitat: 'Selva tropical hasta alturas de 3000m.',
        identification: 'Cuerpo mide 90-100cm. Cola 13-23cm. y altura de 45-65cm. La cornamenta se presenta únicamente en los machos y es bifurcada y alcanza un máximo de 10-12 cm. Manto de color roijzo brillante. En los machos los caninos superiores sobresalen de la mandíbula. Viven solitarios.',
        gestation: 'Durante 6 meses y la hembra pare 1-2 crías.',
        feeding: 'Herbívoro',
        images: []
    },
    'ciervo-rojo': {
        slug: 'ciervo-rojo',
        scientificName: 'Cervus elaphus',
        commonName: 'Ciervo Rojo',
        englishName: 'Elk or Red Deer',
        riskNOM: null,
        riskCITES: null,
        riskIUCN: null,
        order: 'Artiodactyla',
        family: 'Cervidae',
        subfamily: 'Cervinae',
        location: 'Norte de África, Europa, Asia y América del Norte',
        habitat: 'Bosque de Coníferas, del Mediterráneo al Planifolio',
        identification: 'Longitud del cuerpo 185-250cm. Altura de 150cm. Los machos presentan cuernos ramnificados que caen cada año. El manto es de color castaño rojizo en verano y castaño grisáceo en invierno. Alrededor de la cola un manchon amarillo. Viven en grupos separados hembras y machos, solo se unen en temporada de apareamiento.',
        gestation: '240 y 262 días. Una o dos crías por año.',
        feeding: 'Herbívoro',
        images: []
    },
    'cisne-blanco': {
        slug: 'cisne-blanco',
        scientificName: 'Cygnus olor',
        commonName: 'Cisne blanco',
        englishName: 'Mute Swan',
        riskNOM: null,
        riskCITES: null,
        riskIUCN: null,
        order: 'Anseriformes',
        family: 'Anatidae',
        subfamily: null,
        location: 'Australia',
        habitat: 'Lagos',
        identification: 'Longitud de 1.52m. De color blanco, plumas de las alas rizadas. Patas y pico color rojo intenso, pico con protuberancia negra. Nada con el cullo levantado en forma de `S`.',
        gestation: null,
        feeding: 'Plantas acuáticas',
        images: ['cisne-blanco-1']
    },
    'coati': {
        slug: 'coati',
        scientificName: 'Nasua narica',
        commonName: 'Coatí',
        englishName: 'White-nosed coati',
        riskNOM: null,
        riskCITES: null,
        riskIUCN: null,
        order: 'Carnivora',
        family: 'Procyonidae',
        subfamily: null,
        location: 'Desde la zona sudoccidental de EE.UU. hasta Colombia y occidente del Ecuador',
        habitat: 'Selva',
        identification: 'Longitud del cuerpo hasta 65cm. Altura hasta los 30cm. y cola de 6-8cm. El color del manto varía del rojizo a gris. Los labios son de color blanco, así como el hocico que es largo y móvil. La cola muestra anillos difuminados. Las hembras forman grupo numeroso manteniendo alejados a los machos que solo se acercan en temporada de apareamiento. Es diurno.',
        gestation: 'De 74 días. De 3 a 7 crías que permanecen con ella dos años.',
        feeding: 'Omnívoro',
        images: []
    },
    'lagarto-americano': {
        slug: 'lagarto-americano',
        scientificName: 'Crocodylus acutus',
        shortName: 'Cocodrilo Americano',
        commonName: 'Lagarto o Cocodrilo Americano',
        englishName: 'American Crocodile',
        riskNOM: 'protected',
        riskCITES: 'appendixI',
        riskIUCN: 'vulnerable',
        order: 'Crocodylia',
        family: 'Crocodylidae',
        subfamily: null,
        location: 'Desde el sureste de México hasta Perú',
        habitat: 'En cuevas en las margenes de ríos y lagos',
        identification: 'Puede llegar a una longitud de 5m. Color oliva claro, los jóvenes tienen manchas oscuras. Cabeza estrecha y larga, con el morro ligeramente curvado, del que sobresalen los dientes cuando la boca está cerrada. Cola muy desarrollada y potente; al nadar sus extremidades permanecen pegadas al cuerpo. Los párpados se abren y cierran lateralmente. Pueden pasar largos periodos de tiempo sin comer y hasta dos horas sin respirar. Abren el hocico, que es bastante largo, y permanecen así por mucho tiempo emitiendo ruidos muy peculiares. Practicamente no tienen depredadores.',
        gestation: null,
        feeding: 'Carnívoro',
        images: [
            'lagarto-americano-1',
            'lagarto-americano-2',
            'lagarto-americano-3',
            'lagarto-americano-4'
        ]
    },
    'cocodrilo-mexicano': {
        slug: 'cocodrilo-mexicano',
        scientificName: 'Crocodylus moreletii',
        shortName: 'Cocodrilo Mexicano',
        commonName: 'Cocodrilo mexicano o Cocodrilo de pantano',
        englishName: 'Morelet`s Crocodile or Belize Crocodile',
        riskNOM: 'protected',
        riskCITES: 'appendixII',
        riskIUCN: 'vulnerable',
        order: 'Crocodylia',
        family: 'Crocodylidae',
        subfamily: null,
        location: 'México, Belice y Guatemala',
        habitat: 'En grandes pantanos, ciénegas y otros sitios de agua turbia',
        identification: 'Llegan a medir 3m. de longitud. Hocico corto sin extremos redondeados. Color oliva oscuro, los jóvenes son más claros con bandas oscuras en costados y cola. El uso de su piel (escamosa y gruesa) y la pérdida del hábitat ha provocado la diminución de la población natural.',
        gestation: null,
        feeding: 'Carnívoro',
        images: [
            'cocodrilo-mexicano-1',
            'cocodrilo-mexicano-2',
            'cocodrilo-mexicano-3',
            'cocodrilo-mexicano-4',
            'cocodrilo-mexicano-5'
        ]
    },
    'dromedario': {
        slug: 'dromedario',
        scientificName: 'Camelus dromedarius',
        commonName: 'Dromedario',
        englishName: 'Dromedary',
        riskNOM: null,
        riskCITES: null,
        riskIUCN: null,
        order: 'Artiodactyla',
        family: 'Camelidae',
        subfamily: null,
        location: 'Norte de África, Península Arábiga, Oriente Medio',
        habitat: 'Desierto',
        identification: 'Longitud hasta 3m. y altura hasta 2m. al lomo. El color del manto es beige o castaño claro uniforme. Posee una única joroba donde acumula reservas en su grasa, que les ayuda a soportar prolongados períodos sin agua. El labio superior está profundamente hendido y es muy móvil. Gregario. La especie solo existe en forma domesticada.',
        gestation: '15 meses con una sola cría',
        feeding: 'Herbívoro',
        images: []
    },
    'elefante-africano': {
        slug: 'elefante-africano',
        scientificName: 'Loxodonta africana',
        commonName: 'Elefante Africano',
        englishName: 'African elephant',
        riskNOM: null,
        riskCITES: 'appendixI',
        riskIUCN: 'vulnerable',
        order: 'Proboscidea',
        family: 'Elefantidae',
        subfamily: null,
        location: 'África, al sur del Sahara',
        habitat: 'De la sabana a la selva tropical',
        identification: 'Altura de 4m. Longitud de 6-7.5m. Pueden llegar a pesar más de 6 toneladas. Las orejas son enormes, los incisivos superiores forman colmillos. Es un animal social, con una estructura matriarcal de grupo de hasta 100 individuos.',
        gestation: '22-24 meses. Una cría.',
        feeding: 'Herbívoro',
        images: []
    },
    'emu': {
        slug: 'emu',
        scientificName: 'Dromaius novaehollandiae',
        commonName: 'Emú',
        englishName: 'Emu',
        riskNOM: null,
        riskCITES: null,
        riskIUCN: null,
        order: 'Struthioniformes',
        family: 'Dromaidae',
        subfamily: null,
        location: 'Australia',
        habitat: 'Bosques y desiertos',
        identification: 'Altura de 1.80m. Longitud total 2m. Las plumas son de raquiz doble. Plumas de color gris obscuro, presenta una coloración azul en cuello y cabeza que se intensifica en la temporada de apareamiento. Aves solitarias. Presentan tres dedos.',
        gestation: null,
        feeding: 'Omnívoro',
        images: []
    },
    'faisan-dorado': {
        slug: 'faisan-dorado',
        scientificName: 'Chrysolophus pictus',
        commonName: 'Faisán Dorado',
        englishName: 'Golden Pheasant',
        riskNOM: null,
        riskCITES: null,
        riskIUCN: null,
        order: 'Galliformes',
        family: 'Phasianidae',
        subfamily: null,
        location: 'Asia',
        habitat: 'Bosques, vegetación secundaria',
        identification: 'De color rojo cuello y cuerpo de la parte inferior. Presenta un mechón occipital largo de color amarillo a así como cola larga amarilla con tintes negros. En la espalda vivos colores verde, rosa, azul. Las hembras de color parduzco-amarillo.',
        gestation: null,
        feeding: 'Frutas, semillas, hojas e insectos',
        images: ['faisan-dorado-1']
    },
    'ganso-comun': {
        slug: 'ganso-comun',
        scientificName: 'Anser anser',
        commonName: 'Ganso Común',
        englishName: 'Greylag Goose',
        riskNOM: null,
        riskCITES: null,
        riskIUCN: null,
        order: 'Anseriformes',
        family: 'Anatidae',
        subfamily: null,
        location: 'Europa y Asia',
        habitat: 'Lagos y praderas',
        identification: 'Longitud de 75-90cm. Cuerpo grande, con el pico grueso y naranja, plumaje gris pardo, patas rosas y parte caudal inferior blanquecina. La versión domesticada tiene el plumaje completamente blanco.',
        gestation: null,
        feeding: 'Pasto y plantas acuáticas',
        images: [
            'ganso-comun-1',
            'ganso-comun-2',
            'ganso-comun-3'
        ]
    },
    'guanaco': {
        slug: 'guanaco',
        scientificName: 'Lama guanicoe',
        commonName: 'Guanaco',
        englishName: 'Guanaco',
        riskNOM: null,
        riskCITES: 'appendixII',
        riskIUCN: null,
        order: 'Artiodactyla',
        family: 'Camelidae',
        subfamily: null,
        location: 'América del Sur; en Los Andes, hasta La Patagonia y Tierra de Fuego',
        habitat: 'Zonas montañosas secas',
        identification: 'Longitud del cuerpo 180-225cm. De la cola 15-25cm. El cuerpo, excluidas la cabeza y las extremidades, está cubierto de un pelo largo y suave, de color rojizo-pardo en las partes superiores y más claro en las partes inferiores. Cuello largo y delgado. Orejas largas, erguidas y móviles.',
        gestation: '11 meses. Una cría.',
        feeding: 'Herbívoro',
        images: []
    },
    'hocofaisan': {
        slug: 'hocofaisan',
        scientificName: 'Crax rubra',
        commonName: 'Hocofaisán',
        englishName: 'Great Curassow',
        riskNOM: 'threatened',
        riskCITES: 'appendixIII',
        riskIUCN: 'vulnerable',
        order: 'Galliformes',
        family: 'Cracidae',
        subfamily: null,
        location: 'México y Colombia',
        habitat: 'Bosques húmedos primarios',
        identification: 'Longitud de 75-95cm. El macho de color negro brillante con el vientre blanco, cresta rizada y protuberancia amarilla encima del pico. La hembra de color rojiza brillante, con una sombra de negro en la cola, más palida de abajo. Las alas y la cola moteadas; cabeza, cuello y cresta rizada cuadriculados con blanco y negro.',
        gestation: null,
        feeding: 'Frutas, semillas hojas e insectos',
        images: [
            'hocofaisan-1',
            'hocofaisan-2'
        ]
    },
    'iguana-negra': {
        slug: 'iguana-negra',
        scientificName: 'Ctenosaura pectinata',
        shortName: 'Iguana negra',
        commonName: 'Iguana negra o Iguana espinosa mexicana',
        englishName: 'Mexican spiny-tailed iguana',
        riskNOM: 'threatened',
        riskCITES: null,
        riskIUCN: null,
        order: 'Squamata',
        family: 'Iguanidae',
        subfamily: null,
        location: 'En valles de la depresión central de Chuapas. Endemica de México',
        habitat: 'En barrancos cerca de arroyos y en sitios rocosos y terrenos secos',
        identification: 'Puede crecer hasta 140cm. Con espinas en la espalda en forma de peine. Es muy perseguida por su carne y huevos. Excelente trepadora, de movimientos rápidos, su carácter es feroz y mordelona, azotando también la cola, cuando es acorralada. Ponen hasta 50 huevos en un nido que cava en el suelo y eclosionan en 90 días, las crías nacen de color verde brillante, excavando para salir de la tierra. Oscurecen a medida que envejecen. Son diurnas.',
        gestation: null,
        feeding: 'Omnívora',
        images: [
            'iguana-negra-1',
            'iguana-negra-2',
            'iguana-negra-3',
            'iguana-negra-4',
            'iguana-negra-5'
        ]
    },
    'iguana-verde': {
        slug: 'iguana-verde',
        scientificName: 'Iguana iguana',
        commonName: 'Iguana verde',
        englishName: 'Green iguana or Common iguana',
        riskNOM: 'protected',
        riskCITES: 'appendixII',
        riskIUCN: null,
        order: 'Squamata',
        family: 'Iguanidae',
        subfamily: null,
        location: 'En todo el continente americano',
        habitat: 'Vive en los árboles, a las orillas de ríos, arroyos y lagos',
        identification: 'Miden desde 7.5cm. hasta 2m., su cola abarca la mitad del largo. Se mueven rápidamente y son buenas trepadoras. La vivacidad de su coloración varía de acuerdo a la temperatura: a más calor, más brillo.',
        gestation: null,
        feeding: 'Herbívoro e insectívoro',
        images: null
    },
    'jaguar': {
        slug: 'jaguar',
        scientificName: 'Panthera onca',
        commonName: 'Jaguar',
        englishName: 'Jaguar',
        riskNOM: 'dangerOfExtinction',
        riskCITES: 'appendixI',
        riskIUCN: 'nearThreatened',
        order: 'Carnivora',
        family: 'Felidae',
        subfamily: null,
        location: 'Desde el norte de México y gran parte de Centroamérica hasta el Perú, Paraguay y el norte de Argentina',
        habitat: 'Selvas',
        identification: 'Mide hasta los 180cm. de largo, de la cola 90cm. y altura hasta 75cm. El manto de color amarillo obscuro casi rojizocon grandes manchas anilladas en el centro del cuerpo donde aparece una pequeña mancha. La cabeza es grande y pesada, de características toscas y robustas. Vive aislado, solo forma pareja en temporada de reproducción. La especie está fuertemente asociada con la presencia de agua.',
        gestation: 'Unos 100 días. 2-4 crías.',
        feeding: 'Carnívoro',
        images: [
            'jaguar-1',
            'jaguar-2',
            'jaguar-3',
            'jaguar-4',
            'jaguar-5'
        ]
    },
    'jaguar-negro': {
        slug: 'jaguar-negro',
        scientificName: 'Panthera onca',
        commonName: 'Jaguar Negro',
        englishName: 'Jaguar',
        riskNOM: 'dangerOfExtinction',
        riskCITES: 'appendixI',
        riskIUCN: 'nearThreatened',
        order: 'Carnivora',
        family: 'Felidae',
        subfamily: null,
        location: 'Desde el norte de México y gran parte de Centroamérica hasta el Perú, Paraguay y el norte de Argentina',
        habitat: 'Selvas',
        identification: 'Mide hasta los 180cm. de largo, de la cola 90cm. y altura hasta 75cm. El manto de color amarillo obscuro casi rojizocon grandes manchas anilladas en el centro del cuerpo donde aparece una pequeña mancha. La cabeza es grande y pesada, de características toscas y robustas. Vive aislado, solo forma pareja en temporada de reproducción. La especie está fuertemente asociada con la presencia de agua.',
        gestation: 'Unos 100 días. 2-4 crías.',
        feeding: 'Carnívoro',
        images: [
            'jaguar-negro-1',
            'jaguar-negro-2',
            'jaguar-negro-3',
            'jaguar-negro-4',
            'jaguar-negro-5',
            'jaguar-negro-6',
            'jaguar-negro-7'
        ]
    },
    'hipopotamo-nilo': {
        slug: 'hipopotamo-nilo',
        scientificName: 'Hippopotamus amphibius',
        commonName: 'Hipopótamo del Nilo',
        englishName: 'Common Hippopotamus',
        riskNOM: null,
        riskCITES: 'appendixII',
        riskIUCN: 'vulnerable',
        order: 'Artiodactyla',
        family: 'Hippopotamidae',
        subfamily: null,
        location: 'África tropical',
        habitat: 'Ríos y lagos',
        identification: 'Longitud del cuerpo 4.5m. Cola de 55cm. Altura de 150cm. El manto es de color gris con zonas rosáceas, sin pelo. Oídos, ojos y fosas nasales están en la parte superior de su cabeza. Sus colmillos miden hasta 50cm. Adaptado a la vida anfibia. Vive en un grupo de 10-15 individuos.',
        gestation: '233 días. Una única cría que amamanta de 4-6 meses.',
        feeding: 'Herbívoro',
        images: []
    },
    'leon-africano': {
        slug: 'leon-africano',
        scientificName: 'Panthera leo',
        commonName: 'León Africano',
        englishName: 'Lion',
        riskNOM: null,
        riskCITES: 'appendixII',
        riskIUCN: 'vulnerable',
        order: 'Carnivora',
        family: 'Felidae',
        subfamily: null,
        location: 'África al sur del Sáhara, a excepción de la selva, en asia en la selva de Gir; en la India',
        habitat: 'Sabana y matorral; en la India en selva',
        identification: 'De longitud hasta 250cm., cola de 90cm., altura de 100cm. El color de los machos es amarillo oro, únicamente los machos presentan melena, las hembras son más pequeñas y color más claro. Animal sociable, vive en grupo de varias hembras con uno o dos machos. De hábitos de caza generalmente nocturnos.',
        gestation: '105 días. 4 crías.',
        feeding: 'Carnívoro',
        images: [
            'leon-africano-1',
            'leon-africano-2',
            'leon-africano-3'
        ]
    },
    'leon-blanco': {
        slug: 'leon-blanco',
        scientificName: 'Panthera leo',
        commonName: 'León Blanco',
        englishName: 'Lion',
        riskNOM: null,
        riskCITES: 'appendixII',
        riskIUCN: 'vulnerable',
        order: 'Carnivora',
        family: 'Felidae',
        subfamily: null,
        location: 'África al sur del Sáhara, a excepción de la selva, en asia en la selva de Gir; en la India',
        habitat: 'Sabana y matorral; en la India en selva',
        identification: 'De longitud hasta 250cm., cola de 90cm., altura de 100cm. El color de los machos es amarillo oro, únicamente los machos presentan melena, las hembras son más pequeñas y color más claro. Animal sociable, vive en grupo de varias hembras con uno o dos machos. De hábitos de caza generalmente nocturnos.',
        gestation: '105 días. 4 crías.',
        feeding: 'Carnívoro',
        images: [
            'leon-blanco-1',
            'leon-blanco-2',
            'leon-blanco-3',
            'leon-blanco-4',
            'leon-blanco-5',
            'leon-blanco-6',
            'leon-blanco-7'
        ]
    },
    'liebre-patagonia': {
        slug: 'liebre-patagonia',
        scientificName: 'Dolichotis patagonum',
        commonName: 'Liebre de la Patagonia',
        englishName: 'Patagonian Cavy',
        riskNOM: null,
        riskCITES: null,
        riskIUCN: 'nearThreatened',
        order: 'Rodentia',
        family: 'Caviidae',
        subfamily: null,
        location: 'Centro y sur de Argentina',
        habitat: 'Praderas áridas y matorrales con una gran cantidad de espacio abierto',
        identification: 'Mide de 69 a 75cm., cola 4-5cm. De color gris pardo por encima y blanco por debajo. Las patas delanteras tienen cuatro dedos, las traseras 3, todos con una garra afilada. Su forma es la de un roedor de piernas largas con un cuerpo similar a un animal de pezuña, no pertenece al orden de las liebres. Son monógamos.',
        gestation: '96 días. Entre 1 y 3 crías.',
        feeding: 'Herbívoro',
        images: [
            'liebre-patagonia-1',
            'liebre-patagonia-2',
            'liebre-patagonia-3',
            'liebre-patagonia-4',
            'liebre-patagonia-5',
            'liebre-patagonia-6'
        ]
    },
    'lince': {
        slug: 'lince',
        scientificName: 'Lynx rufus',
        commonName: 'Lince',
        englishName: 'Bobcat',
        riskNOM: null,
        riskCITES: 'appendixII',
        riskIUCN: null,
        order: 'Carnivora',
        family: 'Felidae',
        subfamily: null,
        location: 'Zona meridional de Canadá, EE.UU. y parte de México, excepto las grandes praderas centrales norteamericanas.',
        habitat: 'Variable, a menudo en las zonas de bosque',
        identification: 'Longitud del cuerpo hasta 95cm. Altura hasta 50cm. Color del manto es castaño claro con manchas negras. Las orejas parecen puntiagudas por un mechón de pelo obscuro en la punta, su cola es corta y blanca en su extremidad. Son solitarios y territoriales, a veces realizan largas migraciones. Se reproducen cada dos años.',
        gestation: '60 días y paren de 2 a 4 crías.',
        feeding: 'Carnívoro',
        images: []
    },
    'llama': {
        slug: 'llama',
        scientificName: 'Lama glama',
        commonName: 'Llama',
        englishName: 'Llama',
        riskNOM: null,
        riskCITES: null,
        riskIUCN: null,
        order: 'Artiodactyla',
        family: 'Camelidae',
        subfamily: null,
        location: 'Perú, Bolivia y Argentina',
        habitat: 'Montañas',
        identification: 'Longitud superior a 1m. El color de pelo varía desde el blanco y manchado con zonas negras y castañas. Viven en pequeños grupos con un macho dominante, constituidos por 5-10 hembras. Es la forma doméstica del guanaco y criado como animal de carga.',
        gestation: '11 meses y medio. Una sola cría.',
        feeding: 'Herbívoro',
        images: []
    },
    'mapache': {
        slug: 'mapache',
        scientificName: 'Procyon lotor',
        commonName: 'Mapache',
        englishName: 'Nothern Raccoon',
        riskNOM: null,
        riskCITES: null,
        riskIUCN: null,
        order: 'Carnivora',
        family: 'Procyonidae',
        subfamily: null,
        location: 'Desde el sur de Canadá hasta Panamá. Introducido en Francia y la URSS.',
        habitat: 'Cualquier hábitat',
        identification: 'El cuerpo mide 40-60cm. La cola 20-40cm. y de alzada de 22-30cm. El pelo es largo y tupido, de color gris amarillento con difuminados negros. Es característica una máscara negra en ojos y cola anillada. Las patas están provistas de 5 dedos con garras curvadas no retráctiles y las palmas de los pies son desnudas. En verano acumulan grasa para pasar el invierno. Viven solitarios y son territoriales.',
        gestation: '63 días. De dos a cinco crías.',
        feeding: 'Omnívoro',
        images: []
    },
    'martucha': {
        slug: 'martucha',
        scientificName: 'Potos flavus',
        commonName: 'Martucha',
        englishName: 'Kinkajou',
        riskNOM: 'protected',
        riskCITES: null,
        riskIUCN: null,
        order: 'Carnivora',
        family: 'Procyonidae',
        subfamily: null,
        location: 'Del sur de México al Mato Grosso en Brasil',
        habitat: 'Selva',
        identification: 'Longitud del cuerpo hasta los 55cm. y una altura de 25cm. El manto es de pelo corto y aterciopelado de color oliváceo o rojizo. La cola es larga y prensil. Cabeza redonda con orejas pequeñas. Su larga lengua le ayuda en la captura de insectos y succionar néctar de las flores. Es plantígrado y carecen de glándulas anales. Viven solitarios. De hábitos nocturno y arborícola.',
        gestation: '115 días. De una a dos crías que la madre transporta consigo hasta los tres meses.',
        feeding: 'Frugívoro e insectívoro',
        images: []
    },
    'mono-arana': {
        slug: 'mono-arana',
        scientificName: 'Ateles geoffroyi',
        commonName: 'Mono Araña',
        englishName: 'Geoffroy`s Spider Monkey',
        riskNOM: 'dangerOfExtinction',
        riskCITES: 'appendixI',
        riskIUCN: 'endangered',
        order: 'Primates',
        family: 'Atelidae',
        subfamily: null,
        location: 'Parte sur de América Central',
        habitat: 'Selva',
        identification: 'El cuerpo mide 38-63cm. La cola 50-89cm. Su cara es de color negro con bandas blancas alrededor de los ojos, el cuerpo está cubierto de pelo color de gris a castaño. Las patas son largas, su cola es sumamente móvil y presnil. Vive en grupos de 2 a 8 individuos.',
        gestation: '140 días. Única cría que depende de la madre por 10 meses.',
        feeding: 'Omnívoro',
        images: []
    },
    'mono-patas': {
        slug: 'mono-patas',
        scientificName: 'Erythrocebus patas',
        commonName: 'Mono Patas',
        englishName: 'Patas monkey',
        riskNOM: null,
        riskCITES: 'appendixII',
        riskIUCN: null,
        order: 'Primates',
        family: 'Cercopithecidae',
        subfamily: null,
        location: 'Del Senegal a Sudán hasta Tanzania',
        habitat: 'Sabana muy seca',
        identification: 'Longitud de 55cm. Las hembras miden la mitad de los machos aprox. El pelo es corto y áspero, de color rojo ladrillo. Los machos adultos presentan melena, barba y mostachos. Viven en grupos familiares formardos por un macho y varias hembras y jóvenes. Es básicamente terrícola.',
        gestation: '160 días',
        feeding: 'Omnívoro',
        images: []
    },
    'mono-verde': {
        slug: 'mono-verde',
        scientificName: 'Chlorocebus aethiops',
        commonName: 'Mono Verde',
        englishName: 'Green Monkey',
        riskNOM: null,
        riskCITES: 'appendixII',
        riskIUCN: null,
        order: 'Primates',
        family: 'Cercopithecidae',
        subfamily: null,
        location: 'Parte de África al sur del Sahara',
        habitat: 'Sabana y bosques',
        identification: 'El cuerpo mide 40-60cm. y su cola 50-87cm. El hocico es generalmente negro y su perfil blanco. El color del cuerpo es verde oliva claro. Vive en pequeños grupos que van de docenas a cientos de individuos. Son silenciosos.',
        gestation: '165 días. Por lo general, una sola cría.',
        feeding: 'Omnívoro',
        images: []
    },
    'nandu': {
        slug: 'nandu',
        scientificName: 'Rhea americana',
        commonName: 'Ñandú',
        englishName: 'Greater Rhea',
        riskNOM: null,
        riskCITES: 'appendixII',
        riskIUCN: 'nearThreatened',
        order: 'Struthioniformes',
        family: 'Rheidae',
        subfamily: null,
        location: 'Noreste de Brasil y Argentina Central',
        habitat: 'Llanura de pastizales',
        identification: 'Longitud total de 1.28 m. El macho es de mayor tamaño que la hembra. Partes superiores grises, las inferiores de color blanco, y la base del cuello negruzcas. También se puede encontrar en color blanco total. Presenta tres dedos.',
        gestation: null,
        feeding: 'Omnívoro',
        images: []
    },
    'ocelote': {
        slug: 'ocelote',
        scientificName: 'Leopardus pardalis',
        commonName: 'Ocelote',
        englishName: 'Ocelot',
        riskNOM: 'dangerOfExtinction',
        riskCITES: 'appendixI',
        riskIUCN: null,
        order: 'Carnivora',
        family: 'Felidae',
        subfamily: null,
        location: 'De México a América del Sur',
        habitat: 'Selva',
        identification: 'Su cuerpo mide 100cm. Altura hasta 30cm. Su cola mide la mitad de su cuerpo. Color de fondo va entre amarillo-ocre y anaranjado (los de hábitat árido presentan color de fondo grisáceo), su piel es muy preciada. Los caraterizan las líneas negras que parten de la comisura de los ojos hacia atrás, así como de las cejas, por la frente entre las orejas hacia atrás de la cabeza; también una línea transversal en el cuelllo. Caza frecuentemente en los árboles. De costumbres nocturnas. Viven en pareja.',
        gestation: '70 días, en cada parto nacen de 2-4 crías',
        feeding: 'Carnívoro',
        images: []
    },
    'oso-negro': {
        slug: 'oso-negro',
        scientificName: 'Ursus americanus',
        commonName: 'Oso negro americano',
        englishName: 'American black bear',
        riskNOM: 'dangerOfExtinction',
        riskCITES: 'appendixII',
        riskIUCN: null,
        order: 'Carnivora',
        family: 'Ursidae',
        subfamily: null,
        location: 'Canadá, gran parte de EE.UU. y México',
        habitat: 'Todo tipo de bosques',
        identification: 'Longitud hasta 180cm. Su altura en posición cuadrúpeda de 60-90cm. Su manto es de color negro. En otoño se retira a un letargo dentro de una gruta o bajo la protección de árboles.',
        gestation: '220 días. De 2 a 3 oseznos.',
        feeding: 'Omnívoro',
        images: [
            'oso-negro-1',
            'oso-negro-2',
            'oso-negro-3',
            'oso-negro-4',
            'oso-negro-5',
            'oso-negro-6',
            'oso-negro-7'
        ]
    },
    'paca': {
        slug: 'paca',
        scientificName: 'Cuniculus paca',
        commonName: 'Paca o Tepezcuintle',
        englishName: 'Spotted Paca',
        riskNOM: null,
        riskCITES: 'appendixIII',
        riskIUCN: null,
        order: 'Rodentia',
        family: 'Cuniculidae',
        subfamily: null,
        location: 'Del sur de México al Brasil',
        habitat: 'Selva, en proximidades al agua',
        identification: 'Mide de cuerpo 60-79cm. La cola 2-3cm. Su cuerpo grande y robusto, de color pardo obscuro-negro con bandas y manchas blanquecinas en la espalda. Es un animal de hábitos noctunos, es un nadador hábil.',
        gestation: '115-120 días',
        feeding: 'Herbívoro',
        images: []
    },
    'pato-pijije': {
        slug: 'pato-pijije',
        scientificName: 'Dendrocygna autumnalis',
        shortName: 'Pato pijije',
        commonName: 'Pijije alas blancas (Pato pijije)',
        englishName: 'Black-bellied whistling duck',
        riskNOM: null,
        riskCITES: 'appendixIII',
        riskIUCN: null,
        order: 'Anseriformes',
        family: 'Anatidae',
        subfamily: null,
        location: 'Sur de Texas, América Central, hasta el norte de Argentina',
        habitat: 'Charcas, ciénegas de agua dulce',
        identification: 'Longitud de 51-56cm. Patas rosadas y cuello largo. Cobrizo con el vientre negro, pico rosa coral con la punta de color azul grisáceo. Parche blanco a lo largo de la parte inferior del ala.',
        gestation: null,
        feeding: 'Fitófago',
        images: [
            'pato-pijije-1',
            'pato-pijije-2',
            'pato-pijije-3'

        ]
    },
    'pato-real-mexicano': {
        slug: 'pato-real-mexicano',
        scientificName: 'Cairina moschata',
        commonName: 'Pato Real Mexicano',
        englishName: 'Mexican mallard name',
        riskNOM: 'dangerOfExtinction',
        riskCITES: 'appendixIII',
        order: 'Anas',
        family: 'Anatidae',
        subfamily: null,
        location: 'México hasta Argentina y Uruguay',
        habitat: 'Humedales en zonas de clima tropical o subtropical. En altitudes de nivel del mar hasta 1000 mts.',
        identification: 'El macho puede medir 85 cm y la hembra 65 cm. Grande corpulento con todo el cuerpo con plumas negras con reflejos verde, con un peso 2 a 4 kg y las hembras de 1 a 1.5 kg. Presenta ligero dimorfismo sexual, ya que los machos tienen la piel de la cara de color negro y la carúncula de color rojo, mientras que las hembras no tienen carúncula. Las crías son algo deslucidas y no tienen las manchas blancas en las alas hasta que mudan por primera vez.',
        gestation: 'Suele hacer nidos en el suelo o huecos de los árboles. Ellos fabrican sus nidos e incuban los huevos por 35 días, podrían tener de 5 hasta 14 crías.',
        feeding: 'Es un ave que come de todo, se les puede ver comer fruta, verduras, semillas, e incluso insectos.',
        images: [
            'pato-real-mexicano-1',
            'pato-real-mexicano-2'
        ]
    },
    'jabali-collar': {
        slug: 'jabali-collar',
        scientificName: 'Pecari tajacu',
        commonName: 'Pecarí de Collar',
        englishName: 'Collared Peccary',
        riskNOM: null,
        riskCITES: null,
        riskIUCN: null,
        order: 'Artiodactyla',
        family: 'Tayassuidae',
        subfamily: null,
        location: 'Sur de EE.UU. al norte del Brasil',
        habitat: 'Llanuras con matorrales y cactos',
        identification: 'Cuerpo de 75-110cm. de longitud, cola de 1.5-5cm. y altura hasta 55cm. Posee dos dedos largos y uno pequeño en las patas posteriores. En el lomo tienen una glándula que secreta una sustancia aceitosa. El manto tiene un color pardo-negruzco con un collar blanco amarillento poco vistoso, por encima de los hombros. Los caninos son largos y no se ven cuando la boca se encuentra cerrada.',
        gestation: '150 días. Par de crías.',
        feeding: 'Omnívoro',
        images: [
            'jabali-collar-1',
            'jabali-collar-2',
            'jabali-collar-3',
            'jabali-collar-4'
        ]
    },
    'jabali-collar-albino': {
        slug: 'jabali-collar-albino',
        scientificName: 'Pecari tajacu',
        commonName: 'Pecarí de Collar Albino',
        englishName: 'Collared Peccary',
        riskNOM: null,
        riskCITES: null,
        riskIUCN: null,
        order: 'Artiodactyla',
        family: 'Tayassuidae',
        subfamily: null,
        location: 'Sur de EE.UU. al norte del Brasil',
        habitat: 'Llanuras con matorrales y cactos',
        identification: 'Cuerpo de 75-110cm. de longitud, cola de 1.5-5cm. y altura hasta 55cm. Posee dos dedos largos y uno pequeño en las patas posteriores. En el lomo tienen una glándula que secreta una sustancia aceitosa. El manto tiene un color pardo-negruzco con un collar blanco amarillento poco vistoso, por encima de los hombros. Los caninos son largos y no se ven cuando la boca se encuentra cerrada.',
        gestation: '150 días. Par de crías.',
        feeding: 'Omnívoro',
        images: [
            'jabali-collar-albino-1',
            'jabali-collar-albino-2',
            'jabali-collar-albino-3',
            'jabali-collar-albino-4',
            'jabali-collar-albino-5'
        ]
    },
    'puercoespin-cabo': {
        slug: 'puercoespin-cabo',
        scientificName: 'Hystrix africaeaustralis',
        commonName: 'Puercoespín del Cabo',
        englishName: 'Cape Porcupine',
        riskNOM: null,
        riskCITES: null,
        riskIUCN: null,
        order: 'Rodentia',
        family: 'Hystricidae',
        subfamily: null,
        location: 'África subsahariana, excluyendo el desierto de la costa del suroeste',
        habitat: 'En la mayoría de los tipos de vegetación del sur de África. Ausente de los bosques',
        identification: 'Cuerpo de 57-68cm. de largo, la cola de 5 a 12cm. Hocico corto y aplastado cubierto de pelo suave. Sus bigotes son muy largos. En espalda y cola portan sus característicos `aculeos`, que son elásticos y eréctiles, y llegan a medir entre 30 y 40cm. Principalmente nocturnos, aunque pueden verse durante el día. Son solitarios o viven en pequeños grupos familiares. Son el roedor más grande en su región. Sus púas no son venenosas y no son capaces de lanzarlas.',
        gestation: '3 meses. De 2 a 4 crías',
        feeding: 'Frutos, raíces y bulbos',
        images: [
            'puercoespin-cabo-1',
            'puercoespin-cabo-2',
            'puercoespin-cabo-3',
            'puercoespin-cabo-4',
            'puercoespin-cabo-5',
            'puercoespin-cabo-6',
            'puercoespin-cabo-7',
            'puercoespin-cabo-8',
            'puercoespin-cabo-9'
        ]
    },
    'rinoceronte-blanco': {
        slug: 'rinoceronte-blanco',
        scientificName: 'Ceratotherium simum',
        commonName: 'Rinoceronte blanco',
        englishName: 'White rhinoceros',
        riskNOM: null,
        riskCITES: 'appendixI',
        riskIUCN: 'nearThreatened',
        order: 'Perissodactyla',
        family: 'Rhinocerotidae',
        subfamily: null,
        location: 'Sudán, África meridional',
        habitat: 'Pradera, Sabana y proximidades de zonas pantanosas',
        identification: 'Longitud hasta 5m. Altura 2m. Peso de 3 a 5 toneladas. Posee una joroba en la nuca, la cabeza es larga y la mantiene baja, tiene un par de cuernos en la cara, sobre la naariz y entre los ojos. Las orejas son anchas. La piel es de color amarillo pardo o grisáceo. Es sociable y pueden encontrarse grupo de 4-5 individuos. De escasa vista pero de oído finísimo.',
        gestation: 'Aprox. 18 meses, cada 3-4 años',
        feeding: 'Herbívoro',
        images: []
    },
    'tigre-bengala': {
        slug: 'tigre-bengala',
        scientificName: 'Panthera tigris',
        commonName: 'Tigre de Bengala',
        englishName: 'Bengal Tiger',
        riskNOM: null,
        riskCITES: 'appendixI',
        riskIUCN: 'endangered',
        order: 'Carnivora',
        family: 'Felidae',
        subfamily: null,
        location: 'India, Sumatra, Java, Malasia y China meriodional',
        habitat: 'Selva',
        identification: 'Longitud de cuerpo hasta 280cm. altura de 110cm., la cola mide 90cm. En tamaño varía dependiendo de la raza. Manto de color amarillo obscuro con líneas de color negro. Esta especie vive solitaria. Caza por la noche, al acecho, gran variedad de presas. Los adultos no tienen depredadores naturales.',
        gestation: '100 días. De 2 a 4 crías.',
        feeding: 'Carnívoro',
        images: [
            'tigre-bengala-1',
            'tigre-bengala-2',
            'tigre-bengala-3'
        ]
    },
    'tigre-bengala-blanco': {
        slug: 'tigre-bengala-blanco',
        scientificName: 'Panthera tigris',
        commonName: 'Tigre de Bengala Blanco',
        englishName: 'Bengal Tiger',
        riskNOM: null,
        riskCITES: 'appendixI',
        riskIUCN: 'endangered',
        order: 'Carnivora',
        family: 'Felidae',
        subfamily: null,
        location: 'India, Sumatra, Java, Malasia y China meriodional',
        habitat: 'Selva',
        identification: 'Longitud de cuerpo hasta 280cm. altura de 110cm., la cola mide 90cm. En tamaño varía dependiendo de la raza. Manto de color amarillo obscuro con líneas de color negro. Esta especie vive solitaria. Caza por la noche, al acecho, gran variedad de presas. Los adultos no tienen depredadores naturales.',
        gestation: '100 días. De 2 a 4 crías.',
        feeding: 'Carnívoro',
        images: [
            'tigre-bengala-blanco-1',
            'tigre-bengala-blanco-2',
            'tigre-bengala-blanco-3',
            'tigre-bengala-blanco-4',
            'tigre-bengala-blanco-5'
        ]
    },
    'tortuga-desierto': {
        slug: 'tortuga-desierto',
        scientificName: 'Gopherus berlandieri',
        shortName: 'Tortuga del desierto',
        commonName: 'Tortuga del desierto o Galápago tamaulipeco',
        englishName: 'Berlandier`s Tortoise or Texas tortoise',
        riskNOM: 'threatened',
        riskCITES: 'appendixII',
        riskIUCN: null,
        order: 'Testudines',
        family: 'Testudinidae',
        subfamily: null,
        location: 'Sur de EE.UU. y norte de México',
        habitat: 'Zonas desérticas, suelo arenoso y vegetación abierta',
        identification: 'Es pequeña, hasta 22cm. Caparazón es abombado coloración negro pardusco con el centro de los escudos amarillos. El plastrón es plano y su parte delantera se divide en dos, parece una letra V. Patas son fuertes, cilíndricas y columnares (elefantinas), terminan en dedos que tienen poderosas garras. Las patas delanteras y la cabeza presentan escamas. Habita en suelos bien drenados, con matorral, mezquital y chaparral. Se reproduce de abril a julio, pone de 1 a 4 huevos con una incubación entre 90 a 120 días. Es diurna.',
        gestation: null,
        feeding: 'Herbívoro',
        images: ['tortuga-desierto-1']
    },
    'jicoteo': {
        slug: 'jicoteo',
        scientificName: 'Trachemys scripta',
        commonName: 'Tortuga Orejas Rojas o Jicoteo',
        englishName: 'Tortoise',
        order: 'Quelonios',
        family: 'Emydidae',
        subfamily: null,
        location: 'Sureste de estados Unidos y Noreste de México',
        habitat: 'Semiacuáticas, en zonas pantanosas',
        identification: 'Tamaño medio de 12-20 cm con un máximo de 40 cm.También conocida como Galápago de Florida o tortuga Japonesa, está considerada entre las 100 especies exóticas invasoras más peligrosas en el mundo por constituir una amenaza grave para las especies autóctonas. Se ha vuelto muy popular como mascota y esto ha contribuido a su dispersión, en algunos países está prohibida su posesión, transporte, tráfico y comercio.',
        gestation: 'Es ovíparo varias puestas al año de unos 12 a 15 huevos que la hembra entierra en la arena',
        feeding: 'Carnívoros que y al madurar se vuelven omnívoros, consumen. peces, renacuajos o crustáceos y algún aporte vegetal',
        images: [
            'jicoteo-1',
            'jicoteo-2',
            'jicoteo-3',
            'jicoteo-4',
            'jicoteo-5',
            'jicoteo-6',
            'jicoteo-7',
            'jicoteo-8'
        ]
    },
    'tortuga-patas-rojas': {
        slug: 'tortuga-patas-rojas',
        scientificName: 'Celonoidis carbonaria',
        commonName: 'Tortuga Patas Rojas',
        englishName: 'Tortoise',
        riskNOM: 'threatened',
        order: 'Testudines',
        family: 'Testudinidae',
        subfamily: null,
        location: 'América Central y del Sur desde Panamá hasta Brasil-Paraguay.',
        habitat: 'Bosques secos tropicales y matas de monte. Amplia distribución.',
        identification: 'También llamada Tortuga Morrocoy mide entre 20 a 40 cm  su caparazón dependiendo de la variedad. Su color predominantemente amarillo. En la cabeza y patas tienen manchas rojas o anaranjadas.',
        gestation: 'Se pueden reproducir en cualquier época del año. Luego del apareamiento la hembra cava un nido de .30 cm para depositar entre 5 a 15 huevos que eclosionan en septiembre, y pueden hacer 2 puestas al año. La incubación dura de dos a tres meses, las crías son redondas y planas y miden alrededor de 1.5 cm de diámetro. A partir de los 8 años se encuentran listos para iniciar el proceso de reproducción.',
        feeding: 'su alimentación es muy variada pero selectiva solo comerá lo que le gusta, verduras, hortalizas, frutos e incluso proteína animal.',
        images: [
            'tortuga-patas-rojas-1',
            'tortuga-patas-rojas-2',
            'tortuga-patas-rojas-3',
            'tortuga-patas-rojas-4',
            'tortuga-patas-rojas-5',
            'tortuga-patas-rojas-6'
        ]
    },
    'tortuga-sulcata': {
        slug: 'tortuga-sulcata',
        scientificName: 'Centrochelys sulcata',
        commonName: 'Tortuga Sulcata',
        englishName: 'Sulcata Tortoise',
        riskNOM: 'threatened',
        order: 'Testudines',
        family: 'Testudinidae',
        subfamily: null,
        location: 'Su distribución África Central y  Borde sur del Sahara en el Sahel.',
        habitat: 'Sabanas, bosques de acacias, en zonas áridas y secas.',
        identification: 'También conocida como Tortuga de espolones Africana. Por su tamaño la tercera hasta 90 ks. Algunos machos logran alcanza hasta más 100 kg. Su edad promedio es de  45-70 años pero se han conocido ejemplares machos de hasta 100 años.',
        gestation: 'Se pueden reproducir en cualquier época del año pero es más frecuente entre los meses de Septiembre a Noviembre, al terminar  la época de lluvia en su hábitat. Luego del apareamiento la hembra cava varios nidos  de .60 cm de diámetro y 10-15 cm de profundidad donde distribuye los huevos aprox . 15-30 huevos que tardan entre 80 a 180 días en eclosionar. Al nacer las crías miden 4-6 cm de largo su caparazón y pesan 25 gr., son de color amarillo-marrón.',
        feeding: 'Herbívora',
        images: [
            'tortuga-sulcata-1',
            'tortuga-sulcata-2',
            'tortuga-sulcata-3',
            'tortuga-sulcata-4',
            'tortuga-sulcata-5'
        ]
    },
    'venado-axis': {
        slug: 'venado-axis',
        scientificName: 'Axis axis',
        commonName: 'Venado Axis',
        englishName: 'Axis deer or Chital',
        riskNOM: null,
        riskCITES: null,
        riskIUCN: null,
        order: 'Artiodactyla',
        family: 'Cervidae',
        subfamily: 'Cervinae',
        location: 'India',
        habitat: 'Bosques y selvas',
        identification: 'Longitud del cuerpo 110-140cm. altura de 80cm. Color de manto rojo adornada con hileras de manchas blancas. Parte inferior de las extremidades y gargantas son blancas. Únicamente los machos presentan cornamenta caduca y ramificada. Vive en rebaños numerosos.',
        gestation: '7 a 8 meses. Entre 1 y 3 crías.',
        feeding: 'Herbívoro',
        images: []
    }
};
