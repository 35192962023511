import 'whatwg-fetch';
import Promise from 'promise-polyfill';

export const SUBMIT_CONTACT_FORM_START = 'SUBMIT_CONTACT_FORM_START';
function submitContactFormStart () {
    return {
        type: SUBMIT_CONTACT_FORM_START
    };
}

export const SUBMIT_CONTACT_FORM_SUCCESS = 'SUBMIT_CONTACT_FORM_SUCCESS';
function submitContactFormSuccess () {
    return {
        type: SUBMIT_CONTACT_FORM_SUCCESS
    };
}

export const SUBMIT_CONTACT_FORM_ERROR = 'SUBMIT_CONTACT_FORM_ERROR';
function submitContactFormError (error) {
    return {
        type: SUBMIT_CONTACT_FORM_ERROR,
        error: error
    };
}

export function submitContactForm (name, email, subject, message) {
    return (dispatch, getState) => {
        dispatch(submitContactFormStart());

        if (!window.Promise) {
            window.Promise = Promise;
        }

        fetch('https://api.patio23.com/contact-mailer/contact', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                message: message,
                subject: subject
            })
        }).then(function (response) {
            if (response.status >= 200 && response.status < 300) {
                return response.status < 204 ? response.json() : {};
            } else {
                const error = new Error(response.statusText);
                error.response = response;
                throw error;
            }
        }).then(function (response) {
            dispatch(submitContactFormSuccess(response));
        }).catch(function (error) {
            dispatch(submitContactFormError(error));
        });
    };
}
