import '../styles/footer.scss';

import React, {Component} from 'react';

export default class Footer extends Component {
    render () {
        return (
            <footer>
                <div className='centered'>
                    <span
                        className='icon-up'
                        onClick={this.scrollToTop} />
                    <div className='footer-section logo'>
                        <div className='section-info'>
                            <img
                                alt='logo-mini'
                                src={require('../assets/logo-mini.png').default} />
                        </div>
                    </div>
                    {this.renderEmptySpan()}

                    <div className='footer-section'>
                        <div className='section-info'>
                            <span className='icon-location' />
                            <div className='address-wrapper'>
                                <p>Av. Pablo Livas s/n</p>
                                <p>Col. Jardines de La Pastora</p>
                                <p>C.P. 67140</p>
                                <p>Guadalupe, Nuevo León</p>
                            </div>
                        </div>
                    </div>
                    {this.renderEmptySpan()}

                    <div className='footer-section'>
                        <div className='section-info'>
                            <div>
                                <span className='icon-telephone' />
                                <a href='tel:+52-81-8126-8500'>
                                    (81) 8126.8500
                                </a>
                            </div>
                            <div className='email-wrapper'>
                                <span className='icon-email' />
                                <a href='mailto:contacto@parquelapastora.org'>
                                    contacto@parquelapastora.org
                                </a>
                            </div>
                        </div>
                    </div>
                    {this.renderEmptySpan()}

                    <div className='footer-section developed-by'>
                        <div className='section-info social-wrapper'>
                            <a
                                target='_blank'
                                className='icon icon-fe'
                                rel='noopener noreferrer'
                                href='https://www.facebook.com/LaPastoraNL ' />
                            <a
                                target='_blank'
                                className='icon icon-in'
                                rel='noopener noreferrer'
                                href='https://www.instagram.com/zoolapastora' />
                            <p>Desarrollado con <span className='icon-love' /> por <a target='_blank' rel='noopener noreferrer' href='https://www.patio23.com'>Patio23</a></p>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }

    // Empty span with single whiespace in order for justify to work
    renderEmptySpan () {
        return (<span> </span>); // eslint-disable-line react/self-closing-comp
    }

    scrollToTop = () => {
        window.scrollTo(0, 0);
    }
}
